import { _ as _extends } from './sizes-6d76cdc8.esm.js';
import { c as components } from './index-7ef32ef8.esm.js';
import foundations from '../foundations/dist/chakra-ui-theme-foundations.esm.js';
import { mode } from '@chakra-ui/theme-tools';
import { isObject } from '@chakra-ui/utils';
import '../foundations/spacing/dist/chakra-ui-theme-foundations-spacing.esm.js';
import '@chakra-ui/anatomy';
import '../foundations/typography/dist/chakra-ui-theme-foundations-typography.esm.js';
import '@chakra-ui/system';
import '../foundations/borders/dist/chakra-ui-theme-foundations-borders.esm.js';
import '../foundations/breakpoints/dist/chakra-ui-theme-foundations-breakpoints.esm.js';
import '../foundations/colors/dist/chakra-ui-theme-foundations-colors.esm.js';
import '../foundations/radius/dist/chakra-ui-theme-foundations-radius.esm.js';
import '../foundations/shadows/dist/chakra-ui-theme-foundations-shadows.esm.js';
import '../foundations/transition/dist/chakra-ui-theme-foundations-transition.esm.js';
import '../foundations/z-index/dist/chakra-ui-theme-foundations-z-index.esm.js';
import '../foundations/blur/dist/chakra-ui-theme-foundations-blur.esm.js';

var styles = {
  global: function global(props) {
    return {
      body: {
        fontFamily: "body",
        color: mode("gray.800", "whiteAlpha.900")(props),
        bg: mode("white", "gray.800")(props),
        transitionProperty: "background-color",
        transitionDuration: "normal",
        lineHeight: "base"
      },
      "*::placeholder": {
        color: mode("gray.400", "whiteAlpha.400")(props)
      },
      "*, *::before, &::after": {
        borderColor: mode("gray.200", "whiteAlpha.300")(props),
        wordWrap: "break-word"
      }
    };
  }
};
var styles$1 = styles;

var requiredChakraThemeKeys = ["borders", "breakpoints", "colors", "components", "config", "direction", "fonts", "fontSizes", "fontWeights", "letterSpacings", "lineHeights", "radii", "shadows", "sizes", "space", "styles", "transition", "zIndices"];
function isChakraTheme(unit) {
  if (!isObject(unit)) {
    return false;
  }

  return requiredChakraThemeKeys.every(function (propertyName) {
    return Object.prototype.hasOwnProperty.call(unit, propertyName);
  });
}

var direction = "ltr";
var config = {
  useSystemColorMode: false,
  initialColorMode: "light",
  cssVarPrefix: "chakra"
};
var theme = _extends({
  direction: direction
}, foundations, {
  components: components,
  styles: styles$1,
  config: config
});

export { theme as default, isChakraTheme, requiredChakraThemeKeys, theme };
