import _extends from "@babel/runtime/helpers/esm/extends";
import inherits from './util/inherits';
import isAbsent from './util/isAbsent';
import isSchema from './util/isSchema';
import printValue from './util/printValue';
import MixedSchema from './mixed';
import { array as locale } from './locale';
import runTests from './util/runTests';
export default ArraySchema;

function ArraySchema(type) {
  var _this = this;

  if (!(this instanceof ArraySchema)) return new ArraySchema(type);
  MixedSchema.call(this, {
    type: 'array'
  }); // `undefined` specifically means uninitialized, as opposed to
  // "no subtype"

  this._subType = undefined;
  this.innerType = undefined;
  this.withMutation(function () {
    _this.transform(function (values) {
      if (typeof values === 'string') try {
        values = JSON.parse(values);
      } catch (err) {
        values = null;
      }
      return this.isType(values) ? values : null;
    });

    if (type) _this.of(type);
  });
}

inherits(ArraySchema, MixedSchema, {
  _typeCheck: function _typeCheck(v) {
    return Array.isArray(v);
  },
  _cast: function _cast(_value, _opts) {
    var _this2 = this;

    var value = MixedSchema.prototype._cast.call(this, _value, _opts); //should ignore nulls here


    if (!this._typeCheck(value) || !this.innerType) return value;
    var isChanged = false;
    var castArray = value.map(function (v, idx) {
      var castElement = _this2.innerType.cast(v, _extends({}, _opts, {
        path: (_opts.path || '') + "[" + idx + "]"
      }));

      if (castElement !== v) {
        isChanged = true;
      }

      return castElement;
    });
    return isChanged ? castArray : value;
  },
  _validate: function _validate(_value, options, callback) {
    var _this3 = this;

    if (options === void 0) {
      options = {};
    }

    var errors = [];
    var sync = options.sync;
    var path = options.path;
    var innerType = this.innerType;

    var endEarly = this._option('abortEarly', options);

    var recursive = this._option('recursive', options);

    var originalValue = options.originalValue != null ? options.originalValue : _value;

    MixedSchema.prototype._validate.call(this, _value, options, function (err, value) {
      if (err) {
        if (endEarly) return void callback(err);
        errors.push(err);
        value = err.value;
      }

      if (!recursive || !innerType || !_this3._typeCheck(value)) {
        callback(errors[0] || null, value);
        return;
      }

      originalValue = originalValue || value; // #950 Ensure that sparse array empty slots are validated

      var tests = new Array(value.length);

      var _loop = function _loop(idx) {
        var item = value[idx];
        var path = (options.path || '') + "[" + idx + "]"; // object._validate note for isStrict explanation

        var innerOptions = _extends({}, options, {
          path: path,
          strict: true,
          parent: value,
          index: idx,
          originalValue: originalValue[idx]
        });

        tests[idx] = function (_, cb) {
          return innerType.validate ? innerType.validate(item, innerOptions, cb) : cb(null);
        };
      };

      for (var idx = 0; idx < value.length; idx++) {
        _loop(idx);
      }

      runTests({
        sync: sync,
        path: path,
        value: value,
        errors: errors,
        endEarly: endEarly,
        tests: tests
      }, callback);
    });
  },
  _isPresent: function _isPresent(value) {
    return MixedSchema.prototype._isPresent.call(this, value) && value.length > 0;
  },
  of: function of(schema) {
    var next = this.clone();
    if (schema !== false && !isSchema(schema)) throw new TypeError('`array.of()` sub-schema must be a valid yup schema, or `false` to negate a current sub-schema. ' + 'not: ' + printValue(schema));
    next._subType = schema;
    next.innerType = schema;
    return next;
  },
  min: function min(_min, message) {
    message = message || locale.min;
    return this.test({
      message: message,
      name: 'min',
      exclusive: true,
      params: {
        min: _min
      },
      test: function test(value) {
        return isAbsent(value) || value.length >= this.resolve(_min);
      }
    });
  },
  max: function max(_max, message) {
    message = message || locale.max;
    return this.test({
      message: message,
      name: 'max',
      exclusive: true,
      params: {
        max: _max
      },
      test: function test(value) {
        return isAbsent(value) || value.length <= this.resolve(_max);
      }
    });
  },
  ensure: function ensure() {
    var _this4 = this;

    return this.default(function () {
      return [];
    }).transform(function (val, original) {
      // We don't want to return `null` for nullable schema
      if (_this4._typeCheck(val)) return val;
      return original == null ? [] : [].concat(original);
    });
  },
  compact: function compact(rejector) {
    var reject = !rejector ? function (v) {
      return !!v;
    } : function (v, i, a) {
      return !rejector(v, i, a);
    };
    return this.transform(function (values) {
      return values != null ? values.filter(reject) : values;
    });
  },
  describe: function describe() {
    var base = MixedSchema.prototype.describe.call(this);
    if (this.innerType) base.innerType = this.innerType.describe();
    return base;
  }
});